@import '../../../styles/settings.scss';

.preview__container {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.625rem;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.preview__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 1.5rem;
  background-color: $white;
  border-radius: .5rem;
}

.preview__navigation__back {
  button {
    background-color: $white;
    border: 1px solid $majorelle-blue;
    border-radius: .5rem;
    padding: 0.75rem 1.688rem;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 600;
    color: $majorelle-blue;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $lavender-web;
    }
  }
}

.preview__navigation__share {
  button {
    background-color: $majorelle-blue;
    border: 1px solid $majorelle-blue;
    border-radius: .5rem;
    padding: 0.75rem 1.688rem;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 600;
    color: $white;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $mauve;
      border: 1px solid $mauve;
    }
  }
}

.preview__mockup {
  width: 21.875rem;
  background-color: $white;
  border-radius: 1.5rem;
  padding: 3rem 3.5rem;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.10);
}

.preview__mockup__inner {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.preview__mockup__inner__info {
  display: flex;
  flex-direction: column;
  gap: 1.563rem;
}

.preview__mockup__inner__info__image {
  display: flex;
  justify-content: center;
}

.preview__mockup__inner__info__image__inner {
  display: flex;

  border-radius: 50%;
  border: 4px solid $majorelle-blue;
  background-color: $majorelle-blue;
  overflow: hidden;
  width: 7rem;
  height: 7rem;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.preview__mockup__inner__info__details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;

  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    color: $gray;
  }
}

.preview__mockup__inner__links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.preview__underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 28.5vh;
  background-color: $majorelle-blue;
  z-index: -1;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.preview__modal {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(200%);
  transition: all .5s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
  }
}

.preview__modal__container {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 1rem 1.5rem;
  background-color: $jet;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  border-radius: .75rem;

  p {
    color: $white;
  }

  img {
    filter: invert(1) brightness(2);
    width: 1.25rem;
  }
}

@media(max-width: 1024px) {
}

@media (max-width: 540px) {
  .preview__underlay {
    display: none;
  }

  .preview__container {
    padding: 0;
    gap: 0;
    padding-bottom: 3rem;
    background-color: $white;
  }

  .preview__mockup {
    width: 100%;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
    padding: 3.75rem 4.313rem;
  }

  .preview__modal__container {
    p {
      font-size: .85rem;
    }

    img {
      width: 1rem;
    }
  }
}
