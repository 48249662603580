@import '../../../styles/settings.scss';

.links__wrapper {
  display: flex;
  gap: 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  overflow: hidden;
  min-height: 90vh;
}

.links__mockup {
  width: 42.2%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 6.15rem 1.5rem;
  background-color: $white;
  border-radius: .5rem;
  height: 88vh;
}

.links__mockup__image {
  position: relative;
}

.links__mockup__links {
  width: 77%;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: .65rem;
  max-height: 51%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.links__info {
  width: 57.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-radius: .5rem;
}

.links__info__container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem;
  height: fit-content;
}

.links__info__header {
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    color: $gray;
  }
}

.links__info__inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  button {
    width: 100%;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 600;
    color: $majorelle-blue;
    padding: 0.688rem 1.688rem;
    border: 1px solid $majorelle-blue;
    border-radius: .5rem;
    background-color: transparent;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $lavender-web;
    }
  }
}

.links__info__inner__list {
  display: flex;
  flex-direction: column;
  border-radius: .5rem;
  gap: 1.25rem;
  background-color: $white;
}

.links__info__inner__list__empty {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.links__info__inner__list__empty__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;

  h2 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    color: $gray;
    width: 72%;
  }
}

.links__info__footer {
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $timberwolf;
  background-color: $white;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;

  button {
    background-color: $majorelle-blue;
    border: none;
    border-radius: .5rem;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 600;
    color: $white;
    padding: 0.688rem 1.688rem;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $mauve;
    }
  }
}

.links__modal {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(200%);
  transition: all .5s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
  }
}

.links__modal__container {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 1rem 1.5rem;
  background-color: $jet;
  border-radius: .75rem;

  p {
    color: $white;
  }

  img {
    filter: invert(1) brightness(2);
    width: 1.25rem;
  }
}

//  INPUT LINK
.inputlink__wrapper {
  background-color: $seasalt;
  padding: 1.25rem;
  border-radius: .5rem;
}

.inputlink__container {
  display: flex;
  flex-direction: column;
  gap: .75rem;
}

.inputlink__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inputlink__header__number {
  display: flex;
  align-items: center;
  gap: .5rem;
  cursor: grab;

  p {
    color: $gray;
    font-weight: 700;
  }
}

.inputlink__header__button {
  button {
    background-color: transparent;
    border: none;
    font-family: $font-text;
    color: $gray;
    font-weight: 400;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }
  }
}

.inputlink__platform {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  position: relative;

  p {
    font-size: .75rem;
  }
}

.inputlink__platform__input {
  display: flex;
  justify-content: space-between;
  padding: .75rem 1rem;
  border: 1px solid $timberwolf;
  border-radius: .5rem;
  background-color: $white;
}

.inputlink__platform__input__info {
  display: flex;
  align-items: center;
  gap: .75rem;

  p {
    font-size: 1rem;
  }
}

.inputlink__platform__input__button {
  cursor: pointer;
}

.input__link__platform__dropdown {
  width: 100%;
  height: 8rem;
  position: absolute;
  top: 5rem;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: .75rem 1rem;
  background-color: $white;
  border: 1px solid $timberwolf;
  border-radius: .5rem;
  gap: .75rem;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10%);
  transition: all .3s ease-in-out;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.input__link__platform__dropdown__item {
  display: flex;
  align-items: center;
  gap: .75rem;
  border-radius: .3rem;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    background-color: $lavender-web;
  }
}

.inputlink__link {
  display: flex;
  flex-direction: column;
  gap: .25rem;

  p {
    font-size: .75rem;
  }
}

.inputlink__link__info {
  display: flex;
  align-items: center;
  gap: .75rem;
  padding: .75rem 1rem;
  border: 1px solid $timberwolf;
  border-radius: .5rem;
  background-color: $white;

  input {
    width: 100%;
    border: none;
    outline: none;
    font-family: $font-text;
    font-size: 1rem;

    &::placeholder {
      color: $timberwolf;
    }
  }
}

@media (max-width: 1024px) {
  .links__mockup {
    display: none;
  }

  .links__info {
    width: 100%;
  }
}

@media (max-width: 540px) {

  .links__wrapper {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    padding: 1rem;
  }

  .links__info__container {
    gap: 2rem;
    padding: 1.656rem;
  }

  .links__info__header {
    h1 {
      font-size: 1.5rem;
    }
  }

  .links__info__inner__list__empty {
    padding: 2rem 0;
    gap: 1.5rem;

    img {
      width: 42.5%;
    }
  }

  .links__info__inner__list__empty__info {
    h2 {
      font-size: 1.5rem;
    }

    p {
      width: 100%;
    }
  }

  .links__info__footer {
    padding: 1rem;
    justify-content: center;

    button {
      width: 100%;
    }
  }

  .links__modal__container {
    p {
      font-size: .85rem;
    }

    img {
      width: 1rem;
    }
  }
}
