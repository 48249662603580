//  FONT
$font-text: 'Instrument Sans', sans-serif;
$base-size: 1rem;

//  PALLETTE
$majorelle-blue: rgb(99, 60, 255);
$mauve: rgb(190, 173, 255);
$lavender-web: rgb(239, 235, 255);
$jet: rgb(51, 51, 51);
$gray: rgb(115, 115, 115);
$timberwolf: rgb(217, 217, 217);
$seasalt: rgb(250, 250, 250);
$white: rgb(255, 255, 255);
$vermilion: rgb(255, 57, 57);
