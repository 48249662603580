@import '../../../styles/settings.scss';

.home__wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 10%;
  overflow: hidden;
}

.home__logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a:first-of-type {
    transition: all .2s ease-in-out;
    padding: 0.688rem 1.688rem;
    border: 1px solid $majorelle-blue;
    background-color: transparent;
    border-radius: .5rem;
    font-weight: 600;
    color: $majorelle-blue;
    cursor: pointer;

    &:hover {
      background-color: $mauve;
      border: 1px solid $mauve;
      color: $white;
    }
  }

  a:last-of-type {
    transition: all .2s ease-in-out;
    padding: 0.688rem 1.688rem;
    border: 1px solid $majorelle-blue;
    background-color: $majorelle-blue;
    border-radius: .5rem;
    font-weight: 600;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $mauve;
      border: 1px solid $mauve;
    }
  }
}

.home__nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.home__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 90vh;
  width: 100%;
}

.home__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  h1 {
    font-size: 7rem;
    line-height: 7rem;
    font-weight: 600;
  }

  p {
    font-size: 1.5rem;
  }
}

.home__info__nav__btn {
  width: 20rem;
  display: flex;
  justify-content: center;

  a {
    text-align: center;
    width: 100%;
    transition: all .2s ease-in-out;
    padding: 1rem 1.7rem;
    border: 1px solid $majorelle-blue;
    background-color: $majorelle-blue;
    border-radius: .5rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $mauve;
      border: 1px solid $mauve;
    }
  }
}

.home__image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50rem;
  }
}

.home__test__links {
  display: flex;
  flex-direction: column;
  gap: .3rem;

  a {
    color: $mauve;
  }
}

@media (max-width: 1024px) {
  .home__info {
    gap: 1.5rem;

    h1 {
      font-size: 4.5rem;
      line-height: 4.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  .home__image {
    img {
      width: 40rem;
    }
  }
}

@media (max-width: 540px) {
  .home__wrapper {
    gap: 4rem;
    padding: 2rem 5% 2rem 5%;
  }

  .home__logo {
    flex-direction: column;
    gap: 1rem;
  }

  .home__container {
    flex-direction: column-reverse;
    gap: 2rem;
    min-height: 50vh;
  }

  .home__info {
    gap: 1rem;

    h1 {
      font-size: 3rem;
      line-height: 3rem;
    }

    p {
      font-size: 1.1rem;
    }
  }

  .home__info__nav__btn {
    width: 100%;

    a {
      font-size: 1.1rem;
    }
  }

  .home__image {
    img {
      width: 20rem;
    }
  }
}
