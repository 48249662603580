@import '../../../styles/settings.scss';

.navbar__logo__mobile {
  display: none;
}

.navbar__preview__mobile {
  display: none;
}

.navbar__wrapper {
  width: 100vw;
  padding: 1.5rem;
}

.navbar__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-radius: .5rem;
  padding: 1rem 1rem 1rem 1.5rem;

  p {
    font-weight: 600;
  }
}

.navbar__logo {
  display: flex;
}

.navbar__links__middle__container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: -4.625rem;
}

.navbar__links {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 0.688rem 1.688rem;
  border-radius: .5rem;
  cursor: pointer;

  p {
    color: $gray;
  }

  &:hover {
    p {
      color: $majorelle-blue;
    }

    img {
      filter: invert(0) sepia(88%) saturate(5970%) hue-rotate(230deg) brightness(95%) contrast(100%);
    }
  }
}

.navbar__profile {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 0.688rem 1.688rem;
  border-radius: .5rem;
  cursor: pointer;

  p {
    color: $gray;
  }

  &:hover {
    p {
      color: $majorelle-blue;
    }

    img {
      filter: invert(0) sepia(88%) saturate(5970%) hue-rotate(230deg) brightness(95%) contrast(100%);
    }
  }
}

.active {
  background-color: $lavender-web;

  p {
    color: $majorelle-blue;
  }

  img {
    filter: invert(0) sepia(88%) saturate(5970%) hue-rotate(230deg) brightness(95%) contrast(100%);
  }
}

.notActiveNavLink {
  background-color: transparent;
}

.navbar__corner__container {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.navbar__logout {
  border: 1px solid $majorelle-blue;
  border-radius: .5rem;
  padding: 0.625rem 1rem;
  display: flex;
  cursor: pointer;
  transition: all .2s ease-in-out;

  img {
    width: 1.5rem;
    filter: invert(.6) sepia(88%) saturate(5970%) hue-rotate(225deg) brightness(95%) contrast(100%);
  }

  &:hover {
    background-color: $lavender-web;
  }
}

.navbar__preview {
  padding: 0.625rem 1.688rem;
  border: 1px solid $majorelle-blue;
  border-radius: .5rem;
  cursor: pointer;
  transition: all .2s ease-in-out;

  p {
    color: $majorelle-blue;
  }

  &:hover {
    background-color: $lavender-web;
  }
}

@media (max-width: 1024px) {
  .navbar__links__mobile, .navbar__profile__mobile {
    display: none;
  }

  .navbar__logout {
    margin-left: .1rem;
  }
}

@media (max-width: 540px) {
  .navbar__logo__desktop, .navbar__links__mobile, .navbar__profile__mobile, .navbar__preview {
    display: none;
  }

  .navbar__logo__mobile {
    display: block;
    padding-right: 1rem;
  }

  .navbar__wrapper {
    padding: 0;
  }

  .navbar__links__middle__container {
    gap: 0;
    margin-right: -1rem;
  }

  .navbar__corner__container {
    gap: .3rem;
  }

  .navbar__logout {
    padding: .4rem;

    img {
      width: 1.25rem;
    }
  }

  .navbar__preview__mobile {
    display: flex;
    padding: .4rem;
    border: 1px solid $majorelle-blue;
    border-radius: .5rem;
  }
}
