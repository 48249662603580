@import '../../../styles/settings.scss';

.e404__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 10rem 5% 0 5%;
  text-align: center;

  h1 {
    font-size: 4rem;
    color: $vermilion;
  }

  span {
    color: $majorelle-blue;
  }

  a {
    font-size: 1.2rem;
    font-weight: 600;
    color: $jet;
  }
}