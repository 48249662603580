@import '../../../styles/settings.scss';

.userlink__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: .5rem;
  padding: 1rem;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    opacity: .8;
  }
}

.userlink__link__details {
  display: flex;
  align-items: center;
  gap: .5rem;

  p {
    color: $white;
  }

  img {
    width: 1.25rem;
    height: 1.25rem;
    filter: brightness(3);
  }
}
