@import '../../../styles/settings.scss';

.sharelink__container {
  padding: 10.5rem 1.5rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.625rem;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

@media(max-width: 540px) {
  .sharelink__container {
    padding: 0;
    gap: 0;
    padding-bottom: 3rem;
    background-color: $white;
  }
}
