@import '../../../styles/settings.scss';

.profile__wrapper {
  width: 100%;
  display: flex;
  gap: 1.5rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  position: relative;
  overflow: hidden;
  min-height: 90vh;
}

.profile__mockup {
  width: 42.2%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 6.15rem 1.5rem;
  background-color: $white;
  border-radius: .5rem;
}

.profile__mockup__image {
  position: relative;
}

.profile__mockup__image__data {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 70%;
}

.profile__mockup__image__data__image {
  display: flex;
  border-radius: 50%;
  border: 4px solid $majorelle-blue;
  background-color: $majorelle-blue;
  overflow: hidden;
  width: 6rem;
  height: 6rem;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.profile__mockup__image__data__details {
  width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
  }

  p {
    font-size: 0.875rem;
    color: $gray;
  }
}

.profile__mockup__image__links {
  width: 77%;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: .655rem;
}

.profile__info {
  width: 57.8%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  border-radius: .5rem;
}

.profile__info__container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2.5rem 2.5rem 7.188rem 2.5rem;
}

.profile__info__header {
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }

  p {
    color: $gray;
  }
}

.profile__info__inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile__info__inner__photo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem;
  background-color: $seasalt;
  border-radius: .5rem;
}

.profile__info__inner__photo__title {
  p {
    color: $gray;
  }
}

.profile__info__inner__photo__details {
  display: flex;
  align-items: center;
  margin-left: 17%;
}

.profile__info__inner__photo__image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  justify-content: center;
  background-color: $lavender-web;
  border-radius: .5rem;
  overflow: hidden;
  width: 13rem;
  height: 13rem;
  position: relative;
}

.profile__info__inner__photo__image__button {
  position: absolute;
  display: flex;
  align-items: center;
  width: 13rem;
  height: 13rem;
  z-index: 2;

  img {
    width: 100%;
    object-fit: cover;
    z-index: 1;
    cursor: pointer;
  }

  p {
    font-weight: 600;
    padding-bottom: .8rem;
    z-index: 5;
  }

  input {
    display: none;
  }
}

.profile__info__inner__photo__info {
  padding: 1.5rem;

  p {
    font-size: 0.75rem;
    color: $gray;
  }
}

.profile__info__file__error {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;

  img {
    width: 1rem;
    filter: invert(46%) sepia(64%) saturate(5667%) hue-rotate(338deg) brightness(95%) contrast(107%);
  }

  p {
    font-weight: 500;
    color: $vermilion;
  }
}

.profile__info__inner__details {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  padding: 1.25rem;
  background-color: $seasalt;
  border-radius: .5rem;
}

.profile__info__inner__details__input {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    color: $gray;
  }

  input {
    width: 67.1%;
    font-family: $font-text;
    font-size: 1rem;
    color: $jet;
    padding: .75rem 1rem;
    outline: none;
    border: 1px solid $timberwolf;
    border-radius: .5rem;

    &::placeholder {
      opacity: .6;
    }

    &:focus {
      border: 1px solid $majorelle-blue;
      box-shadow: 0 0 2rem rgba(99, 60, 255, .25);
    }
  }
}

.profile__info__footer {
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $timberwolf;

  button {
    opacity: 1;
    background-color: $majorelle-blue;
    border: none;
    border-radius: .5rem;
    font-family: $font-text;
    font-size: 1rem;
    font-weight: 600;
    color: $white;
    padding: 0.688rem 1.688rem;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      background-color: $mauve;
    }
  }
}

.profile__modal {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(200%);
  transition: all .5s ease-in-out;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
  }
}

.profile__modal__container {
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 1rem 1.5rem;
  background-color: $jet;
  border-radius: .75rem;

  p {
    color: $white;
  }

  img {
    filter: invert(1) brightness(2);
    width: 1.25rem;
  }
}

@media (max-width: 1024px) {
  .profile__mockup {
    display: none;
  }

  .profile__info {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .profile__wrapper {
    width: 100%;
    display: flex;
    gap: 1.5rem;
    padding: 1rem;
  }

  .profile__info__container {
    gap: 2.5rem;
    padding: 1.656rem;
  }

  .profile__info__header {
    h1 {
      font-size: 1.5rem;
    }
  }

  .profile__info__inner__photo {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .profile__info__inner__photo__details {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }

  .profile__info__inner__details__input {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    p {
      color: $jet;
      font-size: .75rem;
    }

    input {
      width: 100%;
    }
  }

  .profile__info__inner__photo__info {
    padding: 1.5rem 0 0 0;
  }

  .profile__info__footer {
    padding: 1rem;
    justify-content: center;

    button {
      width: 100%;
    }
  }

  .profile__modal__container {
    p {
      font-size: .85rem;
    }

    img {
      width: 1rem;
    }
  }
}
