@import './settings.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  cursor: default;
  font-family: $font-text;
  background-color: $seasalt;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

a {
  text-decoration: none;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: $base-size;
  font-weight: 400;
  line-height: 150%;
  color: $jet;
}
